export const LOAD_CLIENT_CONFIG = 'LOAD_CLIENT_CONFIG';
export const APPLY_CLIENT_CONFIG = 'APPLY_CLIENT_CONFIG';
export const DISPLAY_NEXT_IMAGE = 'DISPLAY_NEXT_IMAGE';

export function loadClientConfig() {
    return {type: LOAD_CLIENT_CONFIG};
}

export function displayNextImage() {
    return {type: DISPLAY_NEXT_IMAGE};
}

export function applyClientConfig(config) {
    return {type: APPLY_CLIENT_CONFIG, config};
}
