import {applyClientConfig, LOAD_CLIENT_CONFIG} from "../actions";
import axios from 'axios';

const ServiceMiddleware = store => next => async action => {
    next(action);

    switch (action.type) {
        case LOAD_CLIENT_CONFIG:
            let config;
            let configString = localStorage.getItem('clientConfig');
            if (configString !== null) {
                config = JSON.parse(configString);
                console.log('Loaded client config from localStorage: ', config);
            }

            try {
                let response = await axios.get(window.serverUri);
                config = response.data;
                const state = store.getState();

                if (new Date(response.data.timestamp) > state.images.datetime) {
                    console.log('Applying new client config.');
                    localStorage.setItem('clientConfig', JSON.stringify(config));
                }
                else {
                    console.log('No new client config available. Doing nothing.');
                }
            }
            catch (e) {
                console.log('Error when trying to load client configuration: ', e);
            }
            finally {
                next(applyClientConfig(config));
            }

            return;
        default:
            return;
    }
};

export default ServiceMiddleware;