import React from 'react';
import PropTypes from 'prop-types';
import './ImageDisplay.css';

export default class ImageDisplay extends React.Component {
    render() {
        return (
            <img className="image-display" src={this.props.uri} alt={this.props.name}/>
        );
    }
}

ImageDisplay.propTypes = {
    uri: PropTypes.string,
    name: PropTypes.string
};