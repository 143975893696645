import {connect} from 'react-redux';
import ImageLoader from "../components/ImageLoader";
import {displayNextImage, loadClientConfig} from "../actions";
import ImageModel from "../models/ImageModel";

const mapStateToProps = state => {
    let activeImage = new ImageModel(state.images.available[state.images.visibleId]);
    return {
        images: state.images.available,
        activeImage: activeImage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadClientConfig: async () => {
            dispatch(loadClientConfig());
        },
        showNextImage: async () => {
            dispatch(displayNextImage())
        }
    };
};

const MainImageLoader = connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageLoader);

export default MainImageLoader;