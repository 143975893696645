import React from 'react';
import PropTypes from 'prop-types';
import ImageDisplay from "./ImageDisplay";
import ImageModel from "../models/ImageModel";

export default class ImageLoader extends React.Component {
    async componentDidMount() {
        this.props.loadClientConfig();
        setTimeout(() => this.reloadClientConfig(), 5000);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.activeImage.uri !== nextProps.activeImage.uri;
    }

    async reloadClientConfig() {
        console.log('Reloading client configuration.');
        this.props.loadClientConfig();
        setTimeout(() => this.reloadClientConfig(), 5000);
    }

    displayActiveImage = () => {
        let image = this.props.activeImage;
        if (image === undefined || image === null || image.duration === undefined) {
            return;
        }

        console.log(`Scheduling next image after ${image.duration} seconds.`);
        setTimeout(this.props.showNextImage, image.duration * 1000);

        return <ImageDisplay uri={image.uri} name={image.name}/>
    };

    render() {
        return (
            <div>
                {this.displayActiveImage()}
            </div>
        );
    }
}

ImageLoader.propTypes = {
    loadClientConfig: PropTypes.func.isRequired,
    images: PropTypes.arrayOf(PropTypes.instanceOf(ImageModel)).isRequired,
    activeImage: PropTypes.instanceOf(ImageModel),
    showNextImage: PropTypes.func.isRequired
};