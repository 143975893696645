import { combineReducers } from 'redux';
import {APPLY_CLIENT_CONFIG, DISPLAY_NEXT_IMAGE} from "./actions";
import ImageModel from "./models/ImageModel";


function images(state = {
    visibleId: null,
    available: [],
    datetime: null
}, action) {
    let stateToReturn;
    switch (action.type) {
        case APPLY_CLIENT_CONFIG:
            console.log("APPLY_CLIENT_CONFIG: ", action);

            stateToReturn = {...state};

            if (stateToReturn.visibleId === null) {
                stateToReturn.visibleId = 0
            }

            let images = action.config.images;
            let models = [];

            for (let image of images) {
                models.push(new ImageModel({name: image.name, uri: image.uri, duration: image.duration}));
            }

            stateToReturn.available = models;
            stateToReturn.datetime = new Date(action.config.timestamp);

            return stateToReturn;
        case DISPLAY_NEXT_IMAGE:
            stateToReturn = {...state};

            stateToReturn.visibleId++;

            if (stateToReturn.visibleId >= stateToReturn.available.length) {
                stateToReturn.visibleId = 0;
            }
            console.log(stateToReturn);

            return stateToReturn;
        default:
            return state;
    }
}

const digitalSignageApp = combineReducers({
    images
});

export default digitalSignageApp;