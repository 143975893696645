export default class ImageModel {
    constructor(obj) {
        if (obj === undefined || obj === null) {
            return;
        }

        this.name = obj.name;
        this.uri = obj.uri;
        this.duration = obj.duration;
    }
}